<!--
  Page template to be use for pwa page.
-->
<template>
	<div v-if="!$store.state.loading" class="margin-top">
		<BRow v-if="pageTitle">
			<BCol>
				<h1 class="mb-3">{{ pageTitle }}</h1>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" :lg="fullWidth ? '12' : '10'">
				<slot></slot>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';

@Component({
	name: 'PwaTemplate',
	components: { BRow, BCol },
	mixins: [IdMixin, BreadcrumbsManager],

	props: {
		pageTitle: {
			type: String,
			description: 'Top of page title',
			default: null
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	}
})
export default class PwaTemplate extends Vue {
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.planCoverage', 'en'),
					to: { name: Routes.COVERAGE.MY_COVERAGE }
				},
				{
					text: this.$t('breadcrumb.pwa', 'en'),
					to: { name: Routes.COVERAGE.PWA }
				}
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.planCoverage', 'fr'),
					to: { name: Routes.COVERAGE.MY_COVERAGE }
				},
				{
					text: this.$t('breadcrumb.pwa', 'fr'),
					to: { name: Routes.COVERAGE.PWA }
				}
			]
		);
	}
}
</script>

<style lang="scss" scoped>
.margin-top {
	margin-top: 15px !important;
	@media (min-width: 768px) {
		margin-top: 30px !important;
	}
}
</style>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "planCoverage": "Plan Coverage",
      "pwa": "Personal Wellness Account (PWA)"
    }
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "planCoverage": "Couverture du régime",
      "pwa": "Compte Gestion mieux-être (CGM)"
    }
  }
}
</i18n>
