<template>
	<div class="pwa-balance-section" :automation-id="getAutomationId('pwaBalance')">
		<div class="pwa-balance-header">
			<span>{{ $t('header') }}</span>
		</div>
		<BaseAlert
			class="mb-0 warning-text"
			show
			variant="warning"
			alternate
			:automation-id="getAutomationId('pwaBalanceWarning')"
		>
			<p class="m-0">{{ $t('warningText') }}</p>
		</BaseAlert>
		<BaseButton
			:label="$t('button.summary')"
			class="pwa-balance-button"
			:automation-id="getAutomationId('pwaBalanceYearToDateButton')"
			@click="getPwaBalancePdfYearToDate()"
		/>
		<ErrorModal
			:error-title="$t('modal.title')"
			:error-message="$t('modal.message')"
			:has-close-button="false"
			:modal-show="showPwaYearToDatePdfError"
			:automation-id="getAutomationId('pwaYearToDatePdfErrorModal')"
			@change="showPwaYearToDatePdfError = false"
		>
			<BaseButton
				class="mt-2"
				:label="$t('button.ok')"
				:aria-label="$t('button.ok')"
				variant="primary"
				:automation-id="getAutomationId('pwaYearToDatePdfErrorModalButton')"
				@click="showPwaYearToDatePdfError = false"
			/>
		</ErrorModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseAlert from '@/components/common/alert/BaseAlert';
import BaseButton from '@/components/common/base/BaseButton';
import PersonalWellnessAccountInfo from '@/models/PersonalWellnessAccountInfo';
import ErrorModal from '@/components/common/ErrorModal';

// @vue/component
@Component({
	name: 'PwaBalance',
	components: {
		BaseAlert,
		BaseButton,
		ErrorModal
	},
	mixins: [IdMixin]
})
export default class PwaBalance extends Vue {
	showPwaYearToDatePdfError = false;
	/**
	 * Method calls the Api to get the member card pdf,
	 * Then calls another method to download it for the member.
	 */
	getPwaBalancePdfYearToDate() {
		this.showPwaYearToDatePdfError = false;
		this.$store.dispatch('updateLoading', true);
		PersonalWellnessAccountInfo.getPwaYearToDatePdf(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					PersonalWellnessAccountInfo.downloadPwaYearToDatePdf(
						response.data,
						this.$store.state.i18n.locale
					);
				} else {
					this.showPwaYearToDatePdfError = true;
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
			});
	}
}
</script>
<style lang="scss" scoped>
.pwa-balance-header {
	font-family: $font-family-headline;
	font-size: 24px;
	margin-bottom: 15px;
}
.pwa-balance-button {
	margin-top: 15px;
}
.warning-text ::v-deep .alert-title {
	font-family: $font-family-regular;
	font-size: 17px;
}
</style>
<i18n>
{
    "en": {
        "header": "Personal Wellness Account Balance",
		"warningText": "The balance of your Personal Wellness Account doesn’t include any claims that have just been submitted or are currently being processed.",
		"button": {
			"summary": "Year-to-Date Summary",
			"ok": "OK"
		},
		"modal": {
			"title": "We aren't able to access your Personal Wellness Account summary right now.",
			"message": "Give us a call and we’ll get that figured out."
		}
    },
    "fr": {
        "header": "Solde du compte Gestion mieux-être",
		"warningText": "Le solde de votre compte Gestion mieux-être n’inclut pas les demandes de règlement qui viennent tout juste d’être soumises ou qui sont en cours de traitement.",
		"button": {
			"summary": "Relevé cumulatif à ce jour",
			"ok": "OK"
		},
		"modal": {
			"title": "Nous ne sommes pas en mesure d’accéder à votre sommaire de CGM pour l’instant.",
			"message": "Appelez-nous et nous trouverons une solution au problème."
		}
    }
}
</i18n>
