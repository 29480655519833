/**
 * Vuex-orm model to demonstrate the MSS pattern for saving search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class PersonalWellnessAccountInfo extends Model {
	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 */
	static async getPwaBalanceAmounts(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/personal-wellness-benefits/amounts`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200 || response?.status === 204) {
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 */
	static async getPwaCoverageInfo(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/personal-wellness-benefits`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200 || response?.status === 204) {
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api to return a pdf of year to date pwa info
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getPwaYearToDatePdf(username, token, locale) {
		try {
			const apiClientPDF = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});

			const response = await apiClientPDF.get(
				`/${username}/coverages/personal-wellness-benefits/reports/annual/pdf`
			);
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	static downloadPwaYearToDatePdf(data, locale) {
		const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
		const fileLink = document.createElement('a');
		const filename = locale === 'fr' ? 'RelevédeGCM.pdf' : 'PWAReport.pdf';

		fileLink.href = fileURL;
		fileLink.setAttribute('download', filename);
		document.body.appendChild(fileLink);

		fileLink.click();
	}
}
