<template>
	<BaseCard>
		<div class="desc-title">
			<span>{{ $t('descriptionTitle') }}</span>
		</div>
		<div class="info-spacing">
			<span>{{ $t('infoOne') }} </span>
			<BaseButton
				ref="scrollToButton"
				class="pwa-scroll-to-button"
				:pill="false"
				:label="$t('infoOneLink')"
				variant="link"
				automation-id="pwaScrollToButton"
				type="button"
				:aria-label="$t('infoOneLink')"
				@click="scrollToEligibleExpenses()"
			/>
		</div>
		<div class="info-spacing">
			<span>{{ $t('infoTwo') }}</span>
			<span v-if="bookletId">
				<BaseButton
					ref="benefitsBookletButton"
					class="benefit-booklets-button"
					:pill="false"
					:label="$t('infoTwoLinkWiPDF')"
					variant="link"
					automation-id="benefitBookletsButton"
					type="button"
					:aria-label="$t('infoTwoLinkWiPDF')"
					@click="downloadBooklet()"
				/>
			</span>
			<span v-if="!bookletId">
				{{ $t('infoTwoLink') }}
			</span>
		</div>

		<div class="claim-req-header">
			<span>{{ $t('claimRequirementHeader') }}</span>
		</div>
		<div class="info-spacing">
			<span>{{ $t('infoThree') }}</span>
		</div>
		<div class="info-spacing">
			<span>{{ $t('listItemOne') }}</span>
		</div>
		<div class="info-spacing mb-4">
			<span>{{ $t('listItemTwo') }}</span>
		</div>
		<PwaBalance />
		<ErrorModal
			:modal-show="showBookletError"
			:error-title="$t('message.title')"
			:error-message="$t('message.body')"
			@change="onClick"
		>
			<BaseButton
				:label="$t('message.ok')"
				:automation-id="'btn-ok'"
				variant="primary"
				@click="onClick"
			/>
		</ErrorModal>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseButton from '@/components/common/base/BaseButton';
import ErrorModal from '@/components/common/ErrorModal';
import BenefitsBooklet from '@/models/coverage/BenefitsBooklet.js';
import PwaBalance from '@/components/coverage/personal-wellness-account/PwaBalance.vue';

// @vue/component
@Component({
	name: 'PwaCard',
	components: {
		BaseCard,
		BaseButton,
		ErrorModal,
		PwaBalance
	},
	mixins: [IdMixin]
})
export default class PwaCard extends Vue {
	showBookletError = false;
	showBalanceError = false;
	bookletId = null;
	scrollToEligibleExpenses() {
		this.$emit('scrollToEligibleExpenses');
	}
	onClick() {
		this.showBookletError = !this.showBookletError;
	}
	async mounted() {
		try {
			this.bookletId = await BenefitsBooklet.getBenefitsBookletId();
		} catch (e) {
			// this will show the booklet links but show error on fetching
			this.bookletId = null;
		}
	}
	async downloadBooklet() {
		// if there was an error retreiving the booklet id, show the error modal
		if (!this.bookletId) {
			this.showBookletError = true;
		} else {
			const success = await BenefitsBooklet.getBenefitsBooklet();

			if (!success) {
				this.showBookletError = true;
				this.bookletId = null;
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.desc-title {
	font-family: $font-family-headline;
	font-size: 24px;
	margin-bottom: 10px;
}
.claim-req-header {
	font-family: $font-family-headline;
	font-size: 18px;
	margin-bottom: 10px;
}
.info-spacing {
	margin-bottom: 10px;
}
.pwa-balance-collapse {
	margin-top: 20px;
}
.pwa-balance-collapse ::v-deep .collapse-content {
	border-radius: 0px;
}
.pwa-balance-collapse ::v-deep .collapse-content-background {
	border: none;
}
.pwa-balance-collapse ::v-deep .collapse-button-open {
	border-radius: 30px;
	margin-bottom: 27px;
}
.pwa-balance-collapse ::v-deep .collapse-button-closed {
	border-radius: 30px;
}
.pwa-scroll-to-button {
	padding: 0px;
}
.benefit-booklets-button {
	padding: 0px;
}
</style>
<i18n>
{
    "en": {
        "descriptionTitle": "Description",
		"infoOne": "Your Personal Wellness Account (PWA) allows you to cover expenses that are not covered under your other group benefits.",
		"infoOneLink": "View the full list of eligible expenses in the list below.",
		"infoTwo": "Under your PWA, you have access to a set amount of PWA credits for a policy year. For more details on how much PWA credits you have in a year and how to use them, please refer to your ",
		"infoTwoLink": "benefits booklet.",
		"infoTwoLinkWiPDF": "benefits booklet (PDF).",
        "claimRequirementHeader": "Claim Requirements",
        "infoThree": "To ensure your claim gets processed quickly, please include the following:",
        "listItemOne": "• Your paid-in-full receipt showing the date you received the service or purchased the item.",
        "listItemTwo": "• Any related medical documentation (if applicable).",
		"message": {
			"title": "We aren’t able to access your benefits booklet right now.",
			"body": "Give us a call and we’ll get that figured out.",
			"ok": "OK"
		}
    },
    "fr": {
         "descriptionTitle": "Description",
		"infoOne": "Le compte Gestion mieux-être (CGM) vous permet d’obtenir un remboursement pour les dépenses qui ne sont pas couvertes par les autres garanties de votre régime d’assurance collective.",
		"infoOneLink": "Consultez la liste complète des dépenses admissibles ci-dessous.",
		"infoTwo": "Votre CGM vous donne droit à un montant prédéterminé de crédits par année de couverture. Pour savoir combien de crédits sont alloués à votre CGM par année et comment les utiliser, veuillez vous reporter à votre ",
		"infoTwoLink": "brochure de garanties.",
		"infoTwoLinkWiPDF": "brochure de garanties (PDF).",
        "claimRequirementHeader": "Exigences relatives à la demande de règlement",
        "infoThree": "Afin que votre demande de règlement soit traitée rapidement, veuillez y joindre les documents suivants : ",
        "listItemOne": "• Reçu de paiement intégral sur lequel figure la date à laquelle vous avez reçu le service ou acheté l’article.",
		"listItemTwo": "• Tous les documents médicaux connexes (s’il y a lieu).",
		"message": {
			"title": "Nous ne sommes pas en mesure d’accéder à votre brochure de garanties pour l’instant.",
			"body": "Appelez-nous et nous trouverons une solution au problème.",
			"ok": "OK"
		}
    }
}
</i18n>
