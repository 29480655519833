<template>
	<PwaTemplate :page-title="$t('title')">
		<PwaCard @scrollToEligibleExpenses="scrollToEligibleExpenses()" />
		<PwaCoverage v-if="pwaCoverage" ref="pwacoverage" :pwa-coverage="pwaCoverage" />
	</PwaTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import PwaTemplate from '@/pages/coverage/personal-wellness-account/PwaTemplate.vue';
import PwaCard from '@/components/coverage/personal-wellness-account/PwaCard.vue';
import PwaCoverage from '@/components/coverage/personal-wellness-account/PwaCoverage.vue';
import PersonalWellnessAccountInfo from '@/models/PersonalWellnessAccountInfo';

// @vue/component
@Component({
	name: 'PwaPage',
	components: {
		PwaTemplate,
		PwaCard,
		PwaCoverage
	},
	mixins: [IdMixin],
	watch: {
		locale() {
			this.getAllTheInfo();
		}
	}
})
export default class PwaPage extends Vue {
	pwaBalance = null;
	pwaCoverage = null;

	get locale() {
		return this.$root.$i18n.locale;
	}

	async beforeMount() {
		this.getAllTheInfo();
	}

	async getAllTheInfo() {
		this.$store.dispatch('updateLoading', true);

		this.pwaCoverage = await PersonalWellnessAccountInfo.getPwaCoverageInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);

		this.$store.dispatch('updateLoading', false);
	}

	scrollToEligibleExpenses() {
		this.$refs.pwacoverage.scrollToEligibleExpenses();
	}
}
</script>
<i18n>
{
    "en": {
        "title": "Personal Wellness Account (PWA)"
    },
    "fr": {
        "title": "Compte Gestion mieux-être (CGM)"
    }
}
</i18n>
