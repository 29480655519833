<template>
	<div>
		<div id="pwa-title" class="pwa-title">
			<span>{{ $t('title') }}</span>
		</div>
		<div class="sub-title">
			<span>{{ $t('subTitle') }}</span>
		</div>
		<BaseAccordion class="pwa-accordion">
			<BaseAccordionPanel
				v-for="(coverageItem, index) in pwaCoverage"
				:key="index"
				group-name="samplegroups"
				:button-label="coverageItem.shortDescription"
				:border="borderVisible(index)"
			>
				<p class="accordion-label">{{ $t('accordionLabel') }}</p>
				<p>{{ coverageItem.longDescription }}</p>
			</BaseAccordionPanel>
		</BaseAccordion>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseAccordion from '@/components/common/base/BaseAccordion';
import BaseAccordionPanel from '@/components/common/base/BaseAccordionPanel';

// @vue/component
@Component({
	name: 'PwaCoverage',
	components: {
		BaseAccordion,
		BaseAccordionPanel
	},
	mixins: [IdMixin],
	props: {
		pwaCoverage: {
			type: Array,
			required: true
		}
	}
})
export default class PwaCoverage extends Vue {
	isVisible = false;
	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}
	borderVisible(index) {
		return this.pwaCoverage.length === index + 1 ? false : true;
	}
	scrollToEligibleExpenses() {
		//takes a bit for dom to actually update
		setTimeout(function () {
			document.getElementById('pwa-title').scrollIntoView({ behavior: 'smooth' });
		}, 100);
	}
}
</script>
<style lang="scss" scoped>
.pwa-title {
	font-family: $font-family-headline;
	font-size: 21px;
	margin-bottom: 10px;
}
.sub-title {
	font-family: $font-family-regular;
	font-size: 17px;
	margin-bottom: 20px;
}
.pwa-accordion ::v-deep .card {
	border-radius: 0px;
}
.accordion-label {
	font-size: 18px;
	font-family: $font-family-headline;
	margin-bottom: 5px;
}
</style>
<i18n>
{
    "en": {
       "title": "Eligible Expenses",
       "subTitle": " Select from the list of expense categories below to view descriptions, provisions and eligibility details.",
	   "accordionLabel": "Description"
    },
    "fr": {
        "title": "Frais admissibles",
       "subTitle": "Sélectionnez une catégorie de frais dans la liste ci-dessous pour obtenir une description et connaître les modalités et les critères d’admissibilité.",
	   "accordionLabel": "Description"
    }
}
</i18n>
